import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "flex transparent",
  style: {"width":"100%","min-height":"250px","position":"absolute","top":"0"}
}
const _hoisted_2 = {
  class: "col Rotis text-h6 cardDecoration ellipsis",
  style: {"max-width":"100%"}
}
const _hoisted_3 = {
  class: "col text-dark ellipsis-3-lines",
  style: {"max-width":"100%"}
}
const _hoisted_4 = {
  key: 0,
  class: "col text-secondary cardDecoration right"
}
const _hoisted_5 = {
  key: 1,
  class: "row text-secondary cardDecoration right justify-end items-center"
}
const _hoisted_6 = { class: "notification q-pr-xs q-pl-xs flex justify-center content-center q-mr-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    style: {"width":"100%"},
    class: _normalizeClass(["card-button no-padding bg-white card-bg-image left col", { selectable: _ctx.selectable, selected: _ctx.solution }]),
    to: _ctx.selectable ? undefined : _ctx.path,
    "no-caps": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_img, {
        fit: _ctx.isExternalUrl(_ctx.url) && _ctx.isProduct ? 'contain' : 'cover',
        style: {"width":"100%","height":"250px"},
        src: _ctx.image
      }, null, 8, ["fit", "src"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["column card self-end q-pa-sm q-mb-md q-mt-md left", _ctx.isExternalUrl(_ctx.url) && _ctx.isProduct ? 'bg-grey-2' : 'bg-white']),
          style: {"width":"100%"}
        }, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.desc), 1),
          (!_ctx.notification)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t("SeeMore")), 1))
            : _createCommentVNode("", true),
          (_ctx.notification)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", null, _toDisplayString(_ctx.notification), 1)
                ]),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("Notification")), 1)
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ])
    ]),
    _: 1
  }, 8, ["class", "to"]))
}