
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        title: {
            type: String
        },
        desc: {
            type: String
        },
        path: {
            type: String,
            default: null
        },
        url: {
            type: String
        }
    },
    data() {
        return {};
    }
});
